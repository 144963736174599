<template>
	<router-view id="app" />
</template>

<script>
export default {
	name: 'app',
	created() {
		document.addEventListener('SWUpdated', this.showRefresh)
	},
	mounted() {
		// TODO: Auth Check
	},
	methods: {
		showRefresh(evt) {
			this.$notice('New version available!', () => {
				evt.detail.postMessage({action: 'skipWaiting'})
			}, {
				actionLabel: 'Click Here to Update',
				timeout: 10000,
			})
		},
	},
	metaInfo: () => ({
		title: 'Services',
		titleTemplate: '%s | Correspondent',
		meta: [
			{
				charset: 'utf-8',
			},
			{
				'http-equiv': 'X-UA-Compatible',
				content: 'IE-edge',
			},
			{
				name: 'description',
				content: '',
			},
			{
				name: 'viewport',
				content: 'width=device-width, initial-scale=1, maximum-scale=5',
			},
		],
		script: [
			{
				type: 'module',
				src: 'https://unpkg.com/ionicons@5.0.0/dist/ionicons/ionicons.esm.js',
				async: true,
				defer: true,
			},
		],
	})
}
</script>


<style lang="scss">
@import 'styles/partials';

#app {
	font-family: modules.$font-family-primary;
	min-height: 100vh;

	&.frame-header {
		background-color: modules.color_('background', 'dark');
		min-height: 100%;
		height: 80px;
	}
}
</style>
