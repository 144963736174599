///
/// Store Action Types
///

/// MENU
export const MENU_TOGGLE = 'MENU_TOGGLE'

/// MISC
export const SET_LOADING = 'SET_LOADING'
export const SET_ERROR = 'SET_ERROR'

/// AUTHENTICATION
export const LOGIN_USER = 'LOGIN_USER'
export const LOGOUT_USER = 'LOGOUT_USER'
export const SET_ME = 'SET_ME'
export const UNSET_ME = 'UNSET_ME'
export const SET_USER = 'SET_USER'
export const UNSET_USER = 'UNSET_USER'
export const SET_PROFILE = 'SET_PROFILE'

/// REGISTRATION
export const SET_REGISTRATION = 'SET_REGISTRATION'
export const SET_REGISTRATIONS = 'SET_REGISTRATIONS'
export const UNSET_REGISTRATION = 'UNSET_REGISTRATION'
export const UNSET_REGISTRATIONS = 'UNSET_REGISTRATIONS'
export const MOVE_REGISTRATION = 'MOVE_REGISTRATION'

/// USERS
export const SET_USERS = 'SET_USERS'
export const UNSET_USERS = 'UNSET_USERS'

/// ORGANIZATION
export const SET_ORGANIZATION = 'SET_ORGANIZATION'
export const SET_ORGANIZATIONS = 'SET_ORGANIZATIONS'
export const UNSET_ORGANIZATION = 'UNSET_ORGANIZATION'
export const UNSET_ORGANIZATIONS = 'UNSET_ORGANIZATIONS'

/// APPLICATION
export const SET_APPLICATION = 'SET_APPLICATION'
export const SET_APPLICATIONS = 'SET_APPLICATIONS'
export const UNSET_APPLICATION = 'UNSET_APPLICATION'
export const UNSET_APPLICATIONS = 'UNSET_APPLICATIONS'
