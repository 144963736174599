///
/// Vuex Module - Organization
///

import Vue from 'vue'
import { API } from '@/lib/api'
import * as types from '../types'
import { flatten } from '@/lib/utils'

/// STATE
const state = () => ({
	organization: {},
	organizations: [],
})

/// ACTIONS
const actions = {
	async list({ commit }, params) {
		this.dispatch('misc/setLoading', true)

		let organizations
		try {
			organizations = await API.get(`/organizations`, params)
			commit(types.SET_ORGANIZATIONS, organizations)
			this.dispatch('misc/setLoading', false)
		} catch (error) {
			this.dispatch('misc/setError', error.message || error)
			this.dispatch('misc/setLoading', false)
			throw new Error(error.message || error)
		}

		return organizations
	},
	async listPublic({ commit }, params) {
		this.dispatch('misc/setLoading', true)

		let organizations
		try {
			organizations = await API.get(`/organizations/list`, params, {}, false)
			commit(types.SET_ORGANIZATIONS, organizations)
			this.dispatch('misc/setLoading', false)
		} catch (error) {
			this.dispatch('misc/setError', error.message || error)
			this.dispatch('misc/setLoading', false)
			throw new Error(error.message || error)
		}

		return organizations
	},
	async get({ commit }, orgId) {
		let organization
		try {
			organization = await API.get(`/organizations/${orgId}`)
			commit(types.SET_ORGANIZATION, organization)
		} catch (error) {
			this.dispatch('misc/setError', error.message || error)
			throw new Error(error.message || error)
		}

		return organization
	},
	async update({ commit }, { orgId, data }) {
		this.dispatch('misc/setLoading', true)

		let org
		try {
			org = await API.update(`/organizations/${orgId}`, data)
			this.dispatch('misc/setLoading', false)
			commit(types.SET_ORGANIZATION, org)
		} catch (error) {
			this.dispatch('misc/setError', error.message || error)
			this.dispatch('misc/setLoading', false)
			throw new Error(error.message || error)
		}

		return org
	},
	async report({ commit }, params) {
		this.dispatch('misc/setLoading', true)
		params.report = true

		let organizations
		try {
			organizations = await API.get(`/organizations`, params)
			this.dispatch('misc/setLoading', false)
		} catch (error) {
			this.dispatch('misc/setError', error.message || error)
			this.dispatch('misc/setLoading', false)
			throw new Error(error.message || error)
		}

		let csv = ''
		if (organizations.length) {
			let rows = []
			let header = []
			organizations.forEach(row => {
				let flat = flatten(row)
				let head = Object.keys(flat)
				header = [...new Set([...header, ...head])]
				rows.push(flat)
			})

			csv = [
				header,
				...rows.map(row => header.map(f => {
					return JSON.stringify(row[f])
				}))
			].join(`\r\n`)
			return csv
		}

		return csv
	},
	async salesforce({ commit }, orgId) {
		this.dispatch('misc/setLoading', true)
		let org
		try {
			org = await API.get(`/organizations/${orgId}/salesforce`)
			this.dispatch('misc/setLoading', false)
			commit(types.SET_ORGANIZATION, org)
		} catch (error) {
			this.dispatch('misc/setError', error.message || error)
			this.dispatch('misc/setLoading', false)
			throw error
		}

		return org
	},
	async remove({ commit }, orgId) {
		try {
			await API.remove(`/organizations/${orgId}`)
			commit(types.UNSET_ORGANIZATION)
		} catch (error) {
			this.dispatch('misc/setError', error.message || error)
			throw new Error(error.message || error)
		}
	},
	async create({ commit }, data) {
		this.dispatch('misc/setLoading', true)

		let org
		try {
			org = await API.send(`/organizations`, data)
			this.dispatch('misc/setLoading', false)
			commit(types.SET_ORGANIZATION, org)
		} catch (error) {
			this.dispatch('misc/setError', error.message || error)
			this.dispatch('misc/setLoading', false)
			throw new Error(error.message || error)
		}

		return org
	},
	async uploadLogo({ commit }, {orgId, data }) {
		this.dispatch('misc/setLoading', true)

		try {
			const org = await API.send(`/organizations/logo/${orgId}`, data, true, {
				'content-type': 'application/x-www-form-urlencoded'
			})
			this.dispatch('misc/setLoading', false)
			return commit(types.SET_ORGANIZATION, org)
		} catch (error) {
			console.error(error)
			this.dispatch('misc/setLoading', false)
		}
	},
	async unset({ commit}) {
		commit(types.UNSET_ORGANIZATION)
	},
}
/// MUTATIONS
const mutations = {
	[types.SET_ORGANIZATIONS](state, organizations) {
		state.organizations = organizations.map(o => {
			o.nameAddress = [o.name]
			o.address && o.address.city ? o.nameAddress.push(o.address.city) : null
			o.address && o.address.state ? o.nameAddress.push(o.address.state) : null
			return o
		})
	},
	[types.SET_ORGANIZATION](state, organization) {
		state.organization = organization

		const idx = state.organizations.findIndex(o => o.orgId == organization.orgId)
		if (idx > -1) {
			state.organizations[idx] = organization
		} else {
			state.organizations.push(organization)
		}
		Vue.set(state, 'organizations', [...state.organizations])
	},
	[types.UNSET_ORGANIZATION](state) {
		state.organization = {}
	},
}

/// GETTERS
const getters = {
	organizations: state => state.organizations,
	organization: state => state.organization,
}

/// EXPORT
export default {
	namespaced: true,
	state,
	actions,
	mutations,
	getters,
}
