
export const UserStatus = {
	active: 'Active',
	inactive: 'Inactive',
	pending: 'Pending',
	removed: 'Removed',
	crmonly: 'CRM Only',
}

export const RegistrationStatus = {
	accepted: 'Accepted',
	pending: 'Pending',
	removed: 'Removed',
}

export const OrganizationStatus = {
	active: 'Active',
	inactive: 'Inactive',
	pending: 'Pending',
	removed: 'Removed',
}

export const ApplicationStatus = {
	active: 'Active',
	beta: 'Beta',
	inactive: 'Inactive',
	removed: 'Removed'
}
